<template>
<div class="mt-8 bg-white shadow-lg py-8 mx-4 rounded-xl">   

    <FaveManagement  ref="faves" :modal_open="open_faves"></FaveManagement>
    <ArtistRemove ref="artistremove"/>

    <div class="flex flex-col gap-3 relative" v-if="artist && !loading">

      <div class="flex-1 gap-4 lg:gap-0 relative z-0 flex flex-col lg:flex-row overflow-hidden max-w-7xl mb-36">
        <main class="restrict px-4 md:px-12 flex flex-col flex-1 relative z-0 overflow-y-auto focus:outline-none gap-2">
 
          <div class="flex flex-row text-left gap-2 w-full"> 
            <div class="flex flex-col gap-2">
              <h1 class="text-gp_pink-default-500 text-3xl" style="font-weight:600">
                {{ artist.name }}
              </h1>

              <h2  class="!text-gp_navy-default-500 text-xl mb-4 font-semibold" v-if="artist && artist.tier">
                      
                      <span v-if="artist.address" class="border-0 divide-x-0 !text-gp_navy-default-500 " style="border-width:0px;">{{artist.tier.name}}, based in {{artist.address.town}}
                      </span>
              </h2> 
               
              <div v-if="genres.length" class="flex flex-wrap justify-start items-center justify-items-stretch gap-4">
                        <span v-for="genre in genres" :key="genre" class="bg-gp_pink-default-500 text-white px-4 text-xs text-center py-0.5 rounded-2xl justify-items-start items-center">
                          {{genre}}
                        </span> 
                    </div>
              <div class="text-gp_pink-default-500" v-if="!artist || artist.description == null || artist.description.length == 0">
                <p>This artist hasn't completed their profile</p>
              </div>         
            </div> 
          </div>

          <!-- <div v-html="venue.about" class="venue-description" /> -->
          <div class="venue-description text-sm mt-4" v-if="artist.description"> 
            <p v-for="(line, index) in artist.description.split('\n')" :key="index" v-html="line"></p>
          </div>
          <div v-if="artist.videos" class="space-y-4 mt-4">
            <div v-for="(video, index) in artist.videos.filter(f => f != null)" :key="index"> 
              <div v-if="video.includes('youtube.com/watch') || video.includes('youtu.be')|| video.includes('vimeo.com/')">
                <video-embed :ref="'video_' + index" :src="video"></video-embed>
              </div>
              <div v-else-if="video.includes('facebook.com/plugins/')" class="aspect-w-16 aspect-h-9">
                <iframe width="640" height="360" frameborder="0" :ref="'video_' + index" :src="video" allowfullscreen></iframe>               
              </div>
              <div v-else-if="video.includes('instagram.com/p') || video.includes('instagram.com/reel')" class="aspect-w-9 aspect-h-16">
                <iframe :src="video" scrolling="no" height="700" width="670"/>
              </div>
              <div v-else class="mt-6 text-gp_pink-default-500">
                <a v-if="video.includes('https://')" v-bind:href="video" target="_blank" class="flex underline"><VideoCameraIcon class="mr-4"/> {{ video }}</a>
              </div>
            </div>
          </div>
          <div v-if="artist.audio && artist.audio.filter(f => f != null).length > 0">     
            <div v-for="(audio, index) in artist.audio.filter(f => f != null)" :key="index"> 
              <iframe title="audio" :src="changeAudio(audio)" class="w-full" />     
            </div>
          </div>
          <div v-if="artist.bookings" class="hidden  flex-col text-left gap-4">
            <h2 class="font-bold text-lg">
              <span v-if="artist.bookings.length">{{ artist.name }} currently playing at</span>
              <span v-else></span>
            </h2>
            <div class="flex flex-row gap-12" v-for="booking in artist.bookings.slice(0, 2)" :key="booking.id">
              <img v-if="booking.venue && booking.venue.icon" :src="booking.venue.icon" :alt="booking.venue.name + ' logo'" style="width:auto; height:72px;" />
              <div class="flex justify-center flex-col items-start">
                <h3 class="font-bold" v-if="booking.venue">
                  {{ booking.venue.name }}
                </h3>
                <p> 
                </p>
              </div>
            </div>
          </div>
        </main>
        <aside class="relative px-12 flex flex-col gap-4 flex-shrink-0 w-full md:w-96 border-l border-gray-200">
          
        <div v-show="userType === 'VENUE'" class="venue-option gap-4">
          <div>
              <a @click="openFaves()" class="text-gp_pink-default-500 grid grid-flow-col justify-start">
                <StarIcon /><span class="lg:inline-block ml-4">Add artist to favourites</span>
              </a>
          </div>
          <br>
          <div v-if="this.favesNames.length > 0">
            <a class="grid grid-flow-col justify-start pointer-events-none">
              <ClipboardListIcon /><a class="lg:inline-block ml-4">{{ artist.name }} is a part of your following favourite list(s):</a>
            </a>
            <div v-for="list in this.favesNames" class="cursor-pointer" :key="list.id">
              <div @click="$router.push('/favourites/' + list.id)" class="mt-4">
                <span class="text-gp_pink-default-500">{{list.name}}</span>
              </div>
            </div>
          </div>
        </div>            
        <div v-show="userType === 'ARTIST'" class="artist-options grid gap-4">
          <router-link :to="'/artists/' + artist.id" class="text-gp_pink-default-500 grid grid-flow-col justify-start"> 
            <PencilIcon /><span class="lg:inline-block ml-4">Edit Artist Profile</span>
          </router-link>

          <div @click="openRemoveModal(artist)" class="text-gp_pink-default-500 grid grid-flow-col justify-start cursor-pointer"> 
            <TrashIcon /><span class="lg:inline-block ml-4">Remove Profile</span>
          </div>
        </div>
        <img class="object-contain" :src="artist.image ? artist.image.url : require('@/assets/images/placeholder.svg')" :alt="artist.name + ' logo'" />
           
          <agile  :key="galleryKey" v-if="artist.gallery && artist.gallery.length > 0">
            <div v-for="(slide, index) in artist.gallery" :key="index">
              <img :src="slide.conversions.thumb_lg" :alt="artist.name + ' image'" class="h-96 object-cover" />
            </div>
          </agile>
        <div class="mt-2" v-if="artist.song_list !== null && artist.song_list.length > 1">
            <p><strong>Top songs {{artist.name}} performs:</strong></p>
            <br>
            <ol class="list-decimal list-inside">
              <li v-for="(song, index) in artist.song_list" :key="index">{{ song }}</li>
            </ol>
        </div>
        <div class="mt-2" v-if="artist.played_venues.length > 1">
            <p><strong>{{ artist.name }} recently performed at:</strong></p>
            <br>
            <ol class="list-decimal list-inside">
              <li v-for="(venue, index) in artist.played_venues" :key="index">{{ venue }}</li>
            </ol>
        </div> 
      </aside>
    </div> 
  </div>
  <div class="flex flex-col gap-3 relative lg:max-w-3xl px-4 md:pl-8 md:pr-8 py-4" v-else>
    <h1 class="text-2xl col-span-full  font-bold leading-7 text-gray-900 sm:leading-9 sm:truncate">Loading artist profile...</h1> 
  </div>
</div>
</template>

<style type="text/css">

.restrict .embed-responsive iframe
{
    width:100%;
    min-height:400px;
}

.agile__dots.agile__dots
{
  flex-wrap: wrap;
  margin-bottom:16px;
  justify-items: center;
  padding: 0 10px;
}

</style>

<script type="text/javascript">
import FaveManagement from '../../components/modal/FaveManagement.vue'
import ArtistRemove from '../../components/modal/ArtistRemove.vue';
import { apiComputed, authComputed, apiMethods } from '@/state/helpers'
import { PencilIcon, StarIcon, TrashIcon, ClipboardListIcon, VideoCameraIcon } from "@vue-hero-icons/outline"
import { VueAgile } from "vue-agile";
import appConfig from "@/app.config";
import changeAudio from  '@/utils/change-audio.js';  
import NProgress from 'nprogress';

export default
{
    name: "ArtistProfile",
    data()
    {
        return{          
            loading: true,
            appConfig,   
            genres: [],
            artist: {},
            favesNames: [],
            artistFaveLists: [],
            galleryKey: 0, 
            GalleryOptions: {
                dots: true,
                navButtons: false,
                infinite: true,
                autoplay: true,
                autoplaySpeed: 100,
                centerMode: false,
                slidesToShow: 1
            },
            open_faves: false,
            is_in_faves: false,
            is_venue: false
        }
    },
    components:{
        agile: VueAgile, FaveManagement, PencilIcon, StarIcon, TrashIcon, ClipboardListIcon, ArtistRemove, VideoCameraIcon
    },
    computed:{
        userType() {
            return localStorage.getItem('initialRoute') === 'venue' ? 'VENUE' : 'ARTIST'
        },
        ...apiComputed,
        ...authComputed
    },
    mounted() {  

      NProgress.start();
    
      this.getFavesLists()
            .then((resp) =>{
              resp.map((fave) => {
                if(fave.artists.find(f => f.id === this.artist.id)){
                  this.favesNames.push(fave);
                }
              })
            });

      this.getArtist(this.$attrs.id)
        .then((resp) => {
          this.artist = resp;
        }).finally(() =>{

          let genres = [];

          this.artist.specialities.forEach(special => {
              special.genres.forEach(genre => {
                if (genres.indexOf(genre.name) == -1){
                  genres.push(genre.name);
                }
              })
          });

          this.genres = genres;
          NProgress.done();
          this.loading = false;
      })
       
    },
    methods:{
        changeAudio,
        ...apiMethods,
        openFaves(){
          this.$refs.faves.Artist = this.artist;
          this.$refs.faves.toggleModal();
        },
        openRemoveModal($artist){   
          let artistObj = {
            id: $artist.id,
            name: $artist.name, 
            icon: $artist.image ? $artist.image.url : require('@/assets/images/placeholder.svg'),
            tier: $artist.tier.name
          }

          this.$refs.artistremove.show(artistObj, false);
        }
    }
}
</script>