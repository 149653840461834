<template>    
    <div class="space-y-2 w-68">
        <!-- Current: "bg-indigo-800 text-white", Default: "text-indigo-100 hover:bg-indigo-600" -->
        <router-link exact to="/artist" class="hover:bg-indigo-600  hover:text-white group flex items-center px-2 py-2 text-sm font-medium rounded-md" :class="allowFullArtist">
            <!-- Heroicon name: outline/home -->
            <svg class="mr-2 flex-shrink-0 h-6 w-6 text-indigo-300" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6" />
            </svg>
            Dashboard
        </router-link>

        <router-link to="/artist/profiles" class="text-indigo-100 hover:bg-indigo-600 group flex items-center px-2 py-2 text-sm font-medium rounded-md">
            <UserIcon />
            <span class="ml-2">My artist profiles</span>
        </router-link>

        <router-link to="/artist/calendar" class="text-indigo-100 hover:bg-indigo-600  group flex items-center px-2 py-2 text-sm font-medium rounded-md" v-if="allowFullArtist">
            <CalendarIcon />
            <span class="ml-2">Calendar</span>
        </router-link>

        <router-link to="/artist/offers" class="text-indigo-100 hover:bg-indigo-600 group flex items-center px-2 py-2 text-sm font-medium rounded-md" :class="allowFullArtist"  v-if="allowFullArtist">
            <InboxInIcon/>
            <span class="ml-2">New available gigs</span> 
        </router-link>
        <router-link to="/artist/min-fee-offers" class="text-indigo-100 hover:bg-indigo-600 group flex items-center px-2 py-2 text-sm font-medium rounded-md" :class="allowFullArtist"  v-if="allowFullArtist">
            <InboxInIcon/>
            <span class="ml-2">Gigs under minimum fee</span> 
        </router-link>
        <router-link to="/artist/awaiting-venue-response" class="text-indigo-100 hover:bg-indigo-600 group flex items-center px-2 py-2 text-sm font-medium rounded-md" :class="allowFullArtist"  v-if="allowFullArtist">
            <InboxInIcon/>
            <span class="ml-2">Availability given</span> 
        </router-link>

        <router-link to="/artist/final-acceptance" class="text-indigo-100 hidden hover:bg-indigo-600 group items-center px-2 py-2 text-sm font-medium rounded-md" :class="allowFullArtist"  v-if="allowFullArtist">
            <InboxInIcon/>
            <span class="ml-2">Awaiting booking acceptance</span> 
        </router-link>


        <router-link to="/artist/gigs" class="text-indigo-100 hover:bg-indigo-600 group flex items-center px-2 py-2 text-sm font-medium rounded-md" :class="allowFullArtist" v-if="allowFullArtist">
            <TicketIcon/>
            <span class="ml-2">Booked gigs</span>
        </router-link>

      <router-link v-if="hasShowArtistMonthlyInvoices && allowFullArtist && true" to="/artist/monthly-invoices" class="text-indigo-100 hover:bg-indigo-600 group flex items-center px-2 py-2 text-sm font-medium rounded-md" :class="allowFullArtist">
        <CurrencyPoundIcon/>
        <span class="ml-2">Monthly Invoices</span>
      </router-link>
 
        <router-link to="/artist/invoices" class="text-indigo-100 hover:bg-indigo-600 group flex items-center px-2 py-2 text-sm font-medium rounded-md" :class="allowFullArtist" v-if="allowFullArtist">
            <CurrencyPoundIcon/>
            <span class="ml-2">Invoices</span>
        </router-link>
 
        <router-link to="/artist/house-rules" class="text-indigo-100 hover:bg-indigo-600 group flex items-center px-2 py-2 text-sm font-medium rounded-md" :class="allowFullArtist" v-if="allowFullArtist">
            <ClipboardListIcon/>
            <div class="flex justify-between w-full">
                <span class="ml-2">GigPig house rules</span>
                <span class="uppercase font-bold text-[10px] text-white px-2 mr-8 rounded-full bg-red-500">New</span>
            </div>
        </router-link>

        <router-link to="/artist/venue-directory" class="text-indigo-100 hover:bg-indigo-600 group hidden items-center px-2 py-2 text-sm font-medium rounded-md" :class="allowFullArtist" v-if="allowFullArtist">
            <MapIcon/>
            <span class="ml-2">Venue directory</span>
            <span class="uppercase font-bold text-[10px] ml-[52px] text-white px-2 rounded-full bg-red-500">New</span>
        </router-link>

        <router-link to="/artist/messages" class="hidden text-indigo-100 hover:bg-indigo-600 group items-center px-2 py-2 text-sm font-medium rounded-md" :class="allowFullArtist" v-if="allowFullArtist">
            
            <AnnotationIcon/>
            <span class="ml-2">Messages</span>
        </router-link>
 
    </div>
    
</template>

<script>

import { InboxInIcon, TicketIcon, CalendarIcon, UserIcon, AnnotationIcon, CurrencyPoundIcon, ClipboardListIcon, MapIcon } from '@vue-hero-icons/outline'

// eslint-disable-next-line no-unused-vars
const artistClass = "cursor-not-allowed pointer-events-none opacity-50";

export default {
    name: "NavArtist",
    data(){
        return {
            artistClass: "deter_nav"
        }
    },
    props:{
        disableNav: Boolean
    },
    components:{ InboxInIcon, TicketIcon, CalendarIcon, UserIcon, AnnotationIcon, CurrencyPoundIcon, ClipboardListIcon, MapIcon },
    computed:
    {
        allowFullArtist:  function(){  
            return (!this.disableNav);
        },
      hasShowArtistMonthlyInvoices(){
        return this.$store.getters['api/hasShowArtistMonthlyInvoices']
      }
    }
}
</script>